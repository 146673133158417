import styled from "styled-components";

const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  height: ${(props) => (props.home ? "220px" : "130px")};
  position: relative;
  z-index: 0;
`;

const AvatarBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53.594px;
  height: 54.594px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 5px;
    background: linear-gradient(135deg, #ffffff, #ffffff);
    background: -webkit-linear-gradient(310deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

export { Container, AvatarBorder };
